import React from 'react';
import { styled } from '@compiled/react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import ErrorIcon from '@atlaskit/icon/core/error';

type ErrorProps = {
	primaryText: MessageDescriptor;
	secondaryText?: MessageDescriptor;
	isSpaceSelectionError?: boolean;
	isTagDisplayError?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorContainer = styled.div({
	display: 'flex',
	gap: token('space.050'),
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PrimaryText = styled.div({
	color: token('color.text'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SecondaryText = styled.div({
	color: token('color.text.subtlest'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RedText = styled.div({
	color: token('color.text.danger'),
});

const errorIconStyles = xcss({
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

export const ClassificationLevelError = ({
	primaryText,
	secondaryText,
	isSpaceSelectionError,
	isTagDisplayError,
}: ErrorProps) => (
	<ErrorContainer data-testid="classification-level-error">
		<Box xcss={errorIconStyles}>
			<ErrorIcon label="" color={token('color.icon.danger')} spacing="none" />
		</Box>
		{isSpaceSelectionError && (
			<RedText>
				<FormattedMessage {...primaryText} />
			</RedText>
		)}
		{isTagDisplayError && (
			<>
				<PrimaryText>
					<FormattedMessage {...primaryText} />
				</PrimaryText>

				<SecondaryText>
					<FormattedMessage {...secondaryText} />
				</SecondaryText>
			</>
		)}
	</ErrorContainer>
);
