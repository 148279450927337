import React, { useEffect } from 'react';
import type { ApolloError } from 'apollo-client';
import type { MessageDescriptor } from 'react-intl-next';

import { ErrorDisplay } from '@confluence/error-boundary';

import { ClassificationLevelError } from '../ContentClassificationLevel/ClassificationLevelError';
import { useTrackClassificationAnalytics } from '../hooks/useTrackClassificationAnalytics';

import { sharedMessages } from './sharedMessages';

export type ClassificationTagButtonErrorProps = {
	contentLevelError?: ApolloError;
	spaceLevelError?: ApolloError;
	primaryText?: MessageDescriptor;
	secondaryText?: MessageDescriptor;
};

export const ClassificationTagButtonError = ({
	contentLevelError,
	spaceLevelError,
	primaryText,
	secondaryText,
}: ClassificationTagButtonErrorProps) => {
	const { trackRenderTagError } = useTrackClassificationAnalytics();
	useEffect(() => {
		const errorMessage =
			[contentLevelError?.message, spaceLevelError?.message].filter(Boolean).join(' | ') ||
			'Unknown error';
		trackRenderTagError(errorMessage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			{contentLevelError ? <ErrorDisplay error={contentLevelError} /> : null}
			{spaceLevelError ? <ErrorDisplay error={spaceLevelError} /> : null}
			<ClassificationLevelError
				primaryText={primaryText || sharedMessages.fetchErrorTextPrimary}
				secondaryText={secondaryText || sharedMessages.fetchErrorTextSecondary}
				isTagDisplayError
			/>
		</>
	);
};
