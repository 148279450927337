import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';

import { NO_CLASSIFICATION } from './constants/NoClassification';

type MessageProps = {
	currentClassification: string;
	selectedClassification: string;
	classificationChanged: boolean;
	loading?: boolean;
};

const i18n = defineMessages({
	copyTitle: {
		id: 'data-classification.copy-page.change-warning-title',
		defaultMessage: '<h3>Copying content to this space will change content attributes.</h3>',
		description:
			'Title for warning message displayed if user is copying a content to a differently classified space',
	},
	copySetClassificationMessage: {
		id: 'data-classification.copy-page.change-warning-set-classifications',
		defaultMessage:
			'<b>Classification.</b> If copied, this content will inherit the default classification level of the new destination space. Once copied, the content will be classified as ‘{classification}’ according to the default classification level of the destination space.',
		description:
			'Warning for change in classification if user is copying a content from a non-classified to classified space',
	},
	copyUpdateClassificationMessage: {
		id: 'data-classification.copy-page.change-warning-update-classifications',
		defaultMessage:
			'<b>Classification.</b> If copied, this content will inherit the default classification level of the new destination space. Copying content to this space will update its data classification level from ‘{originalClassification}’ to ‘{classification}’.',
		description:
			'Warning for change in classification if user is copying a content to a differently classified space',
	},
	copyRemoveClassificationMessage: {
		id: 'data-classification.copy-page.change-warning-remove-classification',
		defaultMessage:
			'<b>Classification.</b> Copying content to this space will remove its data classification level.',
		description:
			'Warning for change in classification if user is copying a content to a space with no classification',
	},
});

const setWarningMessage = (selectedClassification: string, currentClassification: string) => {
	if (selectedClassification === NO_CLASSIFICATION) {
		return (
			<FormattedMessage
				{...i18n.copyRemoveClassificationMessage}
				values={{
					b: (chunks: React.ReactNode[]) => <Text as="strong">{chunks}</Text>,
				}}
			/>
		);
	} else if (currentClassification === NO_CLASSIFICATION) {
		return (
			<FormattedMessage
				{...i18n.copySetClassificationMessage}
				values={{
					classification: selectedClassification,
					b: (chunks: React.ReactNode[]) => <Text as="strong">{chunks}</Text>,
				}}
			/>
		);
	} else {
		return (
			<FormattedMessage
				{...i18n.copyUpdateClassificationMessage}
				values={{
					classification: selectedClassification,
					originalClassification: currentClassification,
					b: (chunks: React.ReactNode[]) => <Text as="strong">{chunks}</Text>,
				}}
			/>
		);
	}
};

export const ClassificationChangeWarning = ({
	selectedClassification,
	currentClassification,
	classificationChanged,
	loading,
}: MessageProps) => {
	const shouldDisplayClassificationWarning =
		classificationChanged &&
		selectedClassification !== NO_CLASSIFICATION &&
		currentClassification === NO_CLASSIFICATION;

	if (!shouldDisplayClassificationWarning) {
		return null;
	}

	if (loading) {
		return <Spinner size="medium" />;
	}

	return (
		<SectionMessage appearance="warning">
			<FormattedMessage
				{...i18n.copyTitle}
				values={{
					h3: (chunks: React.ReactNode[]) => <Heading size="small">{chunks}</Heading>,
				}}
			/>
			<Text as="p">{setWarningMessage(selectedClassification, currentClassification)}</Text>
		</SectionMessage>
	);
};
