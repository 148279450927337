import React, { useState } from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import Heading from '@atlaskit/heading';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';

import { type ClassificationOption } from '../../common/types';
import { DataClassificationDropdown } from '../../common/ui/data-classification-dropdown';
import { messages } from '../../messages';

import { type EditPopupProps } from './types';

const popupContainerStyles = xcss({
	width: '330px',
	padding: 'space.250',
});

export const EditPopup = <ExtendedType,>({
	options,
	selectedOption,
	customHeading = undefined,
	loading = false,
	errorMessage,
	onEditCancel,
	onEditSubmit,
	onChange,
	isDisabled = false,
	menuShouldScrollIntoView = false, // default to false as it is a popup, disable scroll behavor of select in popup https://product-fabric.atlassian.net/browse/DSP-22142
}: EditPopupProps<ExtendedType>) => {
	const intl = useIntl();

	// This keeps track of the selected classification in the dropdown. selectedOption is the classification that was selected before the edit popup was opened.
	const [selectedClassification, setSelectedClassification] = useState<
		ClassificationOption<ExtendedType> | undefined
	>(selectedOption);

	const onDropdownChange = (selected: ClassificationOption<ExtendedType>) => {
		setSelectedClassification(selected);
	};

	const onUpdateClick = () => {
		selectedClassification && onEditSubmit && onEditSubmit(selectedClassification);
	};

	return (
		<Box
			xcss={popupContainerStyles}
			role="dialog"
			aria-labelledby="data-classification-edit-content-label"
			aria-modal="true"
		>
			<Stack space="space.200">
				<Heading size="small" id="data-classification-edit-content-label">
					{customHeading || intl.formatMessage(messages.classifyContent)}
				</Heading>
				<DataClassificationDropdown
					label={intl.formatMessage(messages.selectClassification)}
					options={options}
					value={selectedClassification}
					onChange={onDropdownChange}
					onInputChange={onChange}
					errorMessage={errorMessage}
					isDisabled={isDisabled}
					menuShouldScrollIntoView={menuShouldScrollIntoView}
				/>
				<Flex justifyContent="end" gap="space.050">
					<Button onClick={onEditCancel} appearance="subtle">
						{intl.formatMessage(messages.cancelButton)}
					</Button>
					<Button
						isLoading={loading}
						onClick={onUpdateClick}
						appearance="primary"
						isDisabled={!selectedClassification || Boolean(errorMessage) || isDisabled}
					>
						{intl.formatMessage(messages.updateButton)}
					</Button>
				</Flex>
			</Stack>
		</Box>
	);
};
