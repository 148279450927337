import React from 'react';
import { styled } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlaskit/platform-feature-flags';

import { LoadableAfterPaint } from '@confluence/loadable';

import type { EditorClassificationTagWrapperProps } from './EditorComponentsV2/EditorClassificationTagWrapper';

export { useShouldRenderEditorActionBarClassificationPlaceholder } from './hooks/useShouldRenderEditorActionBarClassificationPlaceholder';

export { ContentClassificationTag } from './ContentClassificationLevel/ContentClassificationTag';

export { ClassificationChangeWarning } from './ClassificationChangeWarning';

export { ClassificationLevelError } from './ContentClassificationLevel/ClassificationLevelError';

export { Warning } from './constants/Warning';
export { FlagStatus } from './constants/FlagStatus';

export { ClassificationState } from './constants/ClassificationState';

export { NO_CLASSIFICATION } from './constants/NoClassification';
export { useClassificationChangeState } from './hooks/useClassificationChangeState';

export { FormattedSectionMessage } from './FormattedSectionMessage';

export { useIsDataClassificationEnabledForOrg } from './hooks/useIsDataClassificationEnabledForOrg';

export { useIsClassificationEnabledForContentTypeExpansion } from './hooks/useIsClassificationEnabledForContentTypeExpansion';

export { useIsEllipsisClassifyEnabled } from './hooks/useIsEllipsisClassifyEnabled';

export { ClassificationEditModal } from './shared/ClassificationEditModal';

export {
	ClassificationModalContextProvider,
	useClassificationModalDispatchContext,
} from './contexts/ClassificationModalContext';

export type { ContentClassificationMetadata } from './hooks/useContentClassification';

export type { SpaceClassificationMetadata } from './hooks/useDefaultAndSpaceClassificationLevels';

export {
	mockContentClassificationMetadata,
	mockSpaceClassificationMetadata,
	typedMockPublishedClassification,
} from './__local_mocks__/classifications-mock';

const ClassificationPlaceholderStyles = xcss({
	height: '26px',
});

// This placeholder should match the one in EditorActionBar
const ClassificationPlaceholder = <Box xcss={ClassificationPlaceholderStyles} />;

export const DataClassification = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-DataClassification" */ './DataClassification'))
			.DataClassification,
});

export const EditorContentClassificationTag = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorContentClassificationTag" */ './ContentClassificationLevel/EditorContentClassificationTag'
			)
		).EditorContentClassificationTag,
	loading: () => ClassificationPlaceholder,
});

export const EditorDataClassification = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EditorDataClassification" */ './EditorComponent/EditorDataClassification'
			)
		).EditorDataClassification,
});

export const ContentTypeClassification = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ContentTypeClassification" */ './ContentTypeClassification/ContentTypeClassification'
			)
		).ContentTypeClassification,
	loading: (props) => {
		const { contentClassificationMetadata, readOnly } = props;
		const { hasNoClassification } = contentClassificationMetadata;

		const shouldNotRenderLoader =
			(readOnly && hasNoClassification) ||
			(hasNoClassification && fg('dlp_cc-ellipsis-classify-content'));
		if (shouldNotRenderLoader) return null;
		return React.createElement(
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
			styled.div({
				height: '24px',
			}),
			{},
			'',
		);
	},
});

export const DataClassificationHoverButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DataClassificationHoverButton" */ './EditorComponent/DataClassificationHoverButton'
			)
		).DataClassificationHoverButton,
	loading: () => ClassificationPlaceholder,
});

export const EditorClassificationTagWrapper =
	LoadableAfterPaint<EditorClassificationTagWrapperProps>({
		loader: async () =>
			(
				await import(
					/* webpackChunkName: "loadable-EditorClassificationTagWrapper" */ './EditorComponentsV2/EditorClassificationTagWrapper'
				)
			).EditorClassificationTagWrapper,
		loading: (props) => {
			const { shouldRenderPlaceholder } = props;
			if (!shouldRenderPlaceholder) return null;
			return React.createElement(
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
				styled.div({
					height: '26px',
				}),
				{},
				'',
			);
		},
	});
